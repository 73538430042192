<script setup>
import {onMounted, onUnmounted, ref} from "vue";
import {useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import {MemberStore} from "@/uhas/store/member";

const router = useRouter();
const memberStore = MemberStore();

const {activityList, memberData} = storeToRefs(memberStore);

/**
 * Data
 */
const loading = ref(true);
const scrollComponent = ref(null);

const freeEaDialog = ref(false);

/**
 * Computed
 */

/**
 * Mounted
 */
onMounted (() => {
	memberStore.getActivityList()
		.finally(() => {
			loading.value = false;
		});
  window.addEventListener("scroll", handleScroll)
});

onUnmounted(() => {
	window.removeEventListener("scroll", handleScroll)
})

/**
 * Actions
 */
const handleScroll = () => {
	const element = scrollComponent.value;
	const dif = element.getBoundingClientRect().bottom - window.innerHeight;
  if (dif > -5 && dif < 100 && !loading.value) {
		loading.value = true;
    memberStore.getActivityList()
			.finally(() => {
				loading.value = false;
			});
  }
}
</script>

<style lang="scss" scoped>
::v-deep .p-card-body {
	padding: 2rem;
}
::v-deep .p-component-overlay {
	animation: unset !important;
	background-color: rgba(255, 255, 255, 0.4);
}
</style>

<template>
	<Dialog
		v-model:visible="freeEaDialog"
		:closeOnEscape="false"
		header="คุณยังไม่ได้ผูกบัญชีเทรด"
		modal
	>
		<p class="m-0">
			กรุณาผูกบัญชีเทรดเพื่อเป็นสมาชิกกับเรา เนื่องจาก EA ตัวนี้เราให้สิทธิ์เฉพาะสมาชิกของเราเท่านั้น
    </p>
		<template #footer>
			<a href="https://youtu.be/JjWALs0TA6Q" target="_blank">
				<Button label="การเป็น Uhas Member" />
			</a>
		</template>
	</Dialog>

	<div
		ref="scrollComponent"
		class="grid"
	>
		<div class="col-12 sm:col-6 md:col-4">
			<BlockUI
				:blocked="false"
				:baseZIndex="1103"
				:autoZIndex="false"
			>
				<Card class="w-full">
					<template #header>
						<a href="https://uhas.com/ea-uhas-zone" target="_blank">
							<img
								src="https://cdn.uhas.com/activities/0/TheSecretEA2654.jpg"
								class="border-round-top"
							/>
						</a>
					</template>
					<template #title>
						ฟรี EA Uhas Zone
					</template>
					<template #content>
						<p>ฟรี Robot trade โปรแกรมซื้อขายอัตโนมัติ เฉพาะสมาชิก Uhas เท่านั้น</p>
						<p>ใช้ฟรีตลอดชีพ สำหรับเมมเบอร์</p>
						<p>สมาชิก 1 คน สามารถขอ EA ได้มากกว่า 1 ตัว</p>
					</template>
					<template #footer>
						<div class="text-center">
							<Button
								class="p-button-rounded p-button-lg w-full"
								label="รับ EA ฟรี"
								@click="memberData.exness || memberData.xm ? router.push({name: 'free-ea'}) : freeEaDialog = true"
							/>
						</div>
					</template>
				</Card>
			</BlockUI>
		</div>
		<template v-for="(act, i) in activityList">
			<div
				v-if="act"
				:key="`activity-${i}`"
				class="col-12 sm:col-6 md:col-4"
			>
				<BlockUI
					:blocked="!act.is_active"
					:baseZIndex="1103"
					:autoZIndex="false"
				>
					<Card class="w-full">
						<template #header>
							<a :href="act.link_url || '#'">
								<img
									:src="act.picture_url"
									class="border-round-top"
								/>
							</a>
						</template>
						<template #title>
							{{act.title_heading}}
						</template>
						<template #content>
							<p>{{act.title_registration}}</p>
							<p>{{act.title_compete}}</p>
							<p>{{act.title_rewards}}</p>
						</template>
						<template #footer>
							<div class="text-center">
								<Button
									class="p-button-rounded p-button-lg w-full"
									label="ลงทะเบียน"
									:disabled="!act.is_register"
									@click="router.push({name: 'member'})"
								/>
							</div>
						</template>
					</Card>
				</BlockUI>
			</div>
		</template>
	</div>
	<ProgressBar
		mode="indeterminate"
		style="height: 0.3em"
		class="mt-4"
		:class="{'hidden': !loading}"
	/>
</template>
